<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" @keyup.enter="Ipqcdataselet(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="Ipqcdataselet(Noitem)"></el-button>
            </div>
            <label>{{$t('All.请输入工单号')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      Numbers: ''
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    async Ipqcdataselet (row) {
      const ftadata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Ipqc/UserIpqcdata', ftadata)
      if (res.status !== 200) {
        loadingInstance.close()
        this.UserData(null)
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.QcCount(res.response)
    },
    async QcCount (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'IPQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        row.Numbers = res.response
        if (!row.Numbers) {
          this.$message.error(this.$t('All.获取系统单号失败请重新开单'))
        }
        row.Patrol_records.forEach(element => {
          element.Numbers = res.response
          element.Patrol.forEach(elt => {
            elt.Numbers = res.response
          })
        })
        row.Beilist.forEach(element => {
          element.Numbers = res.response
        })
        loadingInstance.close()
        this.$nextTick(() => { // 异步关闭
          this.$message.success(res.msg)
          this.UserData(row)
          this.Addtab(this.$t('All.IPQC检验单') + '-' + row.Numbers, 'IPQCUser')
          this.RemoveTab(this.$t('All.IPQC检验单'))
        })
      } catch (error) {
        loadingInstance.close()
        this.$message.error(this.$t('All.获取系统单号失败请重新开单'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
